<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.Nombre">

    <div class="text-right leading-tight hidden sm:block " style="color:#033A74; margin-top: 10px;">
      <span class="font-semibold" >{{ activeUserInfo.Nombre }}</span><br>
      <span class="font-semibold the-navbar__user-meta" style="color:#00BFFF; margin-top: 15px;">ID Broker  {{activeUserInfo.IdBrokerSupervisor}} </span>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="activeUserInfo.Imagen" key="onlineImg" :src="'https://cotizador.socasesores.com/uploads/'+activeUserInfo.Imagen" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
        <img v-else src="@/assets/images/logo/favicon.png" alt="user-img" width="40" height="40" class="rounded-full cursor-pointer block">
      </div>




      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/perfil').catch(() => {})" v-if="soc">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Perfil</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer inmo-look" @click="$router.push('/datos-perfil-inmo').catch(() => {})" v-if="inmobiliaria">
            <feather-icon class="inmo-icon" icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Perfil Usuario</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer inmo-look" @click="$router.push('/faqs').catch(() => {})">
            <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">FAQ'S</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer inmo-look"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Cerrar sesión</span>
          </li>
        </ul>
      </vs-dropdown-menu>

    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      soc:false,
      inmobiliaria:false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    socData(){
      return this.$store.state.socData
    }
  },
  mounted(){
    if(this.$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo'){
      this.inmobiliaria=true
    }else{
      this.soc = true
    }
  },
  methods: {
    logout() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      const idTokenMultilogin = userInfo.IdToken
      const isSesionMultilogin = userInfo.SesionMultilogin
      // return console.log({ token })

      if( idTokenMultilogin && isSesionMultilogin ){
        this.ActualizaSesionMultilogin();
        // return console.log(1)
      }else{
        // return console.log(2)
        this.$cookie.delete('key');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('socData');
        this.$router.push('/login').catch(() => {})
      }

    },
    ActualizaSesionMultilogin(){
      let self=this
      var objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'CerrarSesionMultilogin',
        objEjecutivo: {
        EMail: JSON.parse(localStorage.getItem('userInfo')).EMail
        
        }
      }
      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

              this.$cookie.delete('key');
              localStorage.removeItem('userInfo');
              localStorage.removeItem('socData');

              if(this.$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo'){
                window.location = 'https://authext.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https://cotizador.socasesores.com/app/login/'
              }else{
                window.location = 'https://auth.sisec.mx/connect/endsession?id_token_hint='+response.data.objContenido.IdToken+'&post_logout_redirect_uri=https://cotizador.socasesores.com/app/login/'
              }
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Cerrar Sesion Multilogin',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },

    ActualizaSesionMultilogin2(url){
    
      let self=this
 
      var objGeneraCodigo={
        url: url
      } 
      this.$axios.post('https://cotizador.socasesores.com/Multilogin/CierraMultilogin.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        response => { 
          this.$router.push('/login').catch(() => {})
        } ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un errros en apiIon',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })
    },
  }
}
</script>

<style lang="css" scoped>
.inmo-look{
  color: #006D4E;
  font-weight: 700;
}

.inmo-look:hover{
  color: #FF7150 !important;
}
</style>
